import {isArray} from '@blg/stdlib';
import type {ApiClient} from '@blg/jsapilib';
import {type BaseField, BaseRelationField, type ObjectDefinition} from '@blg/api-definition';
import {BaseChoiceField, DefinitionHolder} from '@blg/api-definition';

import {get} from 'svelte/store';

import {defininitionHolderStore} from '$stores/definitions/definitionHolder';
import type {ObjectLocation} from '@blg/object-location';

export function getObjectDefinition (
	location: ObjectLocation | string | string[]
): ObjectDefinition {
	const definitionHolder = get(defininitionHolderStore);
	if (!definitionHolder) {
		throw new Error('definitionHolder is required');
	}

	if (isArray(location)) {
		location = location.join('/');
	}

	// Object Definition & Field
	return definitionHolder.getDefinition(location);
}

export async function getAllDefinitions (apiClient: ApiClient): Promise<void> {
	const definitionHolder = await DefinitionHolder.createFromApiClient(apiClient, {static: true});
	defininitionHolderStore.setDefinitionHolder(definitionHolder);
}

export function getFieldDefinition (
	objectDef: ObjectDefinition,
	fieldPath: string
): BaseField {
	const fields = fieldPath.split('.');
	if (fields.length === 1) {
		return getFieldDeep(objectDef, fieldPath);
	}
	const firstFieldDef = getFieldDeep(objectDef, fields[0]);
	if (!(firstFieldDef instanceof BaseRelationField)) {
		throw new Error(`${fields[0]} is not a BaseRelationField`);
	}
	const targetObject = firstFieldDef.getTargetObject();
	const remainingPath = fields.slice(1).join('.');
	return getFieldDefinition(targetObject, remainingPath);
}

/**
 * Get a field from a definition, even if it's in a child definition. Useful when nested fields
 * @param definition
 * @param fieldName ex: 'billing.status'
 */
export function getFieldDeep (definition: ObjectDefinition, fieldName: string): BaseField {
	if (!definition.hasField(fieldName, true)) {
		// not in this def, maybe in a child def ?
		for (const childDefinition of definition.getChildrenDefDeep()) {
			if (childDefinition.hasField(fieldName, true)) {
				// found in a child
				return childDefinition.getField(fieldName);
			}
		}
	}

	// always do the root getField call to get an error in case of the field doesn't exist in inheritance chain, return the root field else
	return definition.getField(fieldName);
}

export function getChoiceTitle (
	location: ObjectLocation | string | string[],
	fieldPath: string,
	name: string
) {
	const def = getObjectDefinition(location);
	const fieldDef = getFieldDefinition(def, fieldPath);
	if (!(fieldDef instanceof BaseChoiceField)) {
		return name;
	}
	const title = fieldDef.getChoiceTitleByValue(name);

	return title || name;
}
